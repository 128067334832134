<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
		class="cassie-vertical-md"
	>
		<CookieTemplateLayout
			:header-title="'Configure Cookie Text Template' | useLabels('Configure Cookie Text Template')"
			:header-caption="'Configure a text template' | useLabels('Configure a text template')"
		>
			<template #content>
				<Message
					v-if="cookieTextTemplate.isSystem && !cloneMode"
					type="warning"
				>
					You cannot edit system default Cookie Text Templates. Please ensure they meet your business needs before use or clone this and edit accordingly.
				</Message>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<TextField
									v-model="cookieTextTemplate.name"
									label="Name *"
									:disabled="!userCanCreateUpdate || (cookieTextTemplate.isSystem && !cloneMode)"
									:rules="{required: true, max: 50}"
								/>
							</v-col>
							<v-col cols="6">
								<Dropdown
									v-model="cookieTextTemplate.brandId"
									:disabled="isEdit || cloneMode || !userCanCreateUpdate || !clientMultiBranded || singleBrand || (cookieTextTemplate.isSystem && !cloneMode)"
									:items="brandItems"
									custom-sort
									label="Brand *"
									:rules="{ required: clientMultiBranded}"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<Textarea
									v-model="cookieTextTemplate.description"
									label="Description *"
									no-resize
									:disabled="!userCanCreateUpdate || (cookieTextTemplate.isSystem && !cloneMode)"
									:rules="{required: true, max: 1000}"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						Header and Footer
					</template>
					<template #subtitle>
						Select, create or edit custom Headers and Footers for your cookie banner.
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<Dropdown
									v-model="cookieTextTemplate.headerId"
									:items="headerItems"
									custom-sort
									:disabled="!userCanCreateUpdate || (cookieTextTemplate.isSystem && !cloneMode)"
									label="Header *"
								/>
							</v-col>
							<v-col
								v-if="userCanCreateUpdate && !cookieTextTemplate.isSystem"
								cols="6"
							>
								<TextButton
									@click="openCreateHeaderFooter('Header')"
								>
									<v-icon>
										mdi-plus
									</v-icon>
									Create
								</TextButton>

								<TextButton
									:disabled="!cookieTextTemplate.headerId"
									@click="openEditHeaderFooter('Header', cookieTextTemplate.headerId)"
								>
									<v-icon>
										mdi-pencil
									</v-icon>
									Edit Selected Header
								</TextButton>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<Dropdown
									v-model="cookieTextTemplate.footerId"
									:items="footerItems"
									custom-sort
									:disabled="!userCanCreateUpdate || (cookieTextTemplate.isSystem && !cloneMode)"
									label="Footer *"
								/>
							</v-col>
							<v-col
								v-if="userCanCreateUpdate && !cookieTextTemplate.isSystem"
								cols="6"
							>
								<TextButton
									@click="openCreateHeaderFooter('Footer')"
								>
									<v-icon>
										mdi-plus
									</v-icon>
									Create
								</TextButton>
								<TextButton
									:disabled="!cookieTextTemplate.footerId"
									@click="openEditHeaderFooter('Footer', cookieTextTemplate.footerId)"
								>
									<v-icon>
										mdi-pencil
									</v-icon>
									Edit Selected Footer
								</TextButton>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<PreBannerTextCard
					:pre-banner.sync="cookieTextTemplate.preBanner"
					:user-can-create-update="userCanCreateUpdate && !(cookieTextTemplate.isSystem && !cloneMode)"
					:is-system="cookieTextTemplate.isSystem"
					:read-only="cookieTextTemplate.isSystem && !cloneMode"
				/>
				<GpcBannerTextCard
					:gpc-banner.sync="cookieTextTemplate.gpcBanner"
					:user-can-create-update="userCanCreateUpdate && !(cookieTextTemplate.isSystem && !cloneMode)"
					:is-system="cookieTextTemplate.isSystem"
					:read-only="cookieTextTemplate.isSystem && !cloneMode"
				/>
				<BannerTextCard
					:banner.sync="cookieTextTemplate.banner"
					:user-can-create-update="userCanCreateUpdate && !(cookieTextTemplate.isSystem && !cloneMode)"
					:is-system="cookieTextTemplate.isSystem"
					:read-only="cookieTextTemplate.isSystem && !cloneMode"
				/>
				<HeaderFooterFormModal
					v-if="showHeaderFooterForm"
					:type-id="headersAndFootersTypeIdEnum.CONSENT_BANNERS"
					:form-to-edit="headerFooterFormData"
					:selected-brand-id="cookieTextTemplate.brandId"
					:form-type="selectedFormType"
					@updated="loadHeadersFooters"
					@set-selected-header:headerId="setSelectedHeaderFooter('header', $event)"
					@set-selected-footer:footerId="setSelectedHeaderFooter('footer', $event)"
					@close="() => {
						headerFooterFormData = null
						showHeaderFooterForm = false
					}"
				/>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							{{ backToOverviewText }}
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userCanCreateUpdate && (!cookieTextTemplate.isSystem || cloneMode)"
							@click="handleSubmit(submit)"
						>
							{{ isEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</CookieTemplateLayout>
	</ValidationForm>
</template>
<script>
import { mapGetters } from 'vuex'
import CookieTemplateLayout from '../cookie-template-layout.vue'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import Textarea from '../../../../../../../shared/components/textarea.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import TextButton from '../../../../../../../shared/components/text-button.vue'
import PreBannerTextCard from './pre-banner-text-card.vue'
import GpcBannerTextCard from './gpc-banner-text-card.vue'
import BannerTextCard from './banner-text-card.vue'
import PageActionRow from '../../../../../../../shared/components/page-action-row.vue'
import HeaderFooterFormModal from '../../../../shared/header-footer-form-modal.vue'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM } from '../../../../../../../shared/state/brands.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { getFooters, getHeaders } from '../../../../../../../shared/utils/api/headers-and-footers.js'
import { headersAndFootersTypeIdEnum } from '../../../../../../../shared/enums/headers-and-footers.js'
import { postCookieTextTemplate, putCookieTextTemplate } from '../../../../../../../shared/utils/api/cookies/cookie-text-templates.js'
import { COOKIE_TEXT_TEMPLATES } from '../../../../../router/route-names.js'
import { CAN_CREATE_UPDATE_COOKIE_TEXT_TEMPLATES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import Message from '../../../../../../../shared/components/message.vue'
export default {
	components: {
		CookieTemplateLayout,
		ValidationForm,
		SectionCard,
		TextField,
		Dropdown,
		Textarea,
		PrimaryActionButton,
		SecondaryActionButton,
		TextButton,
		PreBannerTextCard,
		GpcBannerTextCard,
		BannerTextCard,
		PageActionRow,
		HeaderFooterFormModal,
		Message
	},
	props: {
		cookieTextTemplateToEdit: Object,
		accessedVia: String,
		cloneMode: {
			type: Boolean,
			default: false
		},
		readOnly: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			brandOptions,
			showSnackbar,
			headersAndFootersTypeIdEnum,
			clientMultiBranded,
			singleBrand,
			defaultBrandInSelect
		}
	},
	data () {
		const ACCESSED_VIA_MANAGE_COOKIE_BANNER = 'manage-cookie-banner'
		const ACCESSED_VIA_COOKIE_TEXT_TEMPLATES = 'cookie-text-templates'
		return {
			cookieTextTemplate: JSON.parse(JSON.stringify(this.cookieTextTemplateToEdit || {
				name: '',
				brandId: this.defaultBrandInSelect,
				description: '',
				headerId: null,
				footerId: null,
				preBanner: {
					preBannerText: 'We use cookies on our website. Some are necessary for our site to function, while others are optional but help improve your experience.',
					acceptAllButtonText: 'Accept All',
					rejectAllButtonText: 'Reject All',
					cookieSettingsButtonText: 'Cookie Settings'
				},
				gpcBanner: {
					gpcBannerTitle: 'GPC Detected',
					gpcBannerText: 'We have detected a Global Privacy Control (GPC) signal, please confirm if you wish to accept all cookies including or excluding those flagged as GPC.',
					acceptAllButtonText: 'Accept All',
					acceptAllExcGpcButtonText: 'Accept All Excluding GPC',
					backButtonText: 'Back'
				},
				banner: {
					submitPreferencesButtonText: 'Submit Preferences',
					userConsentTabLabelText: 'User Consent',
					legitimateInterestTabLabelText: 'Legitimate Interests',
					acceptAllButtonText: 'Accept All',
					rejectAllButtonText: 'Reject All',
					optInLabel: 'Opt In',
					optOutLabel: 'Opt Out'
				}
			})),
			headers: [],
			footers: [],
			showHeaderFooterForm: false,
			headerFooterFormData: false,
			selectedFormType: null,
			ACCESSED_VIA_MANAGE_COOKIE_BANNER,
			ACCESSED_VIA_COOKIE_TEXT_TEMPLATES
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_TEXT_TEMPLATES)
		},
		isEdit () {
			return !!this.cookieTextTemplateToEdit && !this.cloneMode
		},
		backToOverviewText () {
			if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) return 'Back to Cookie Banner'
			else return 'Back to Overview'
		},
		brandItems () {
			// ensures when viewing universal object it displays in dropdown
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		},
		headerItems () {
			let headers = this.headers.map(({ id, name, brandId, brandName }) => ({
				text: `${name} - ${brandName}`,
				value: id,
				brandId: brandId
			}))

			if (this.cookieTextTemplate.brandId === UNIVERSAL_BRAND) {
				headers = headers.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				headers = headers.filter(({ brandId }) => brandId === UNIVERSAL_BRAND || brandId === this.cookieTextTemplate.brandId)
			}

			return [
				{
					text: 'None',
					value: null
				},
				...headers.sort((a, b) => a.text.localeCompare(b.text))
			]
		},
		footerItems () {
			let footers = this.footers.map(({ id, name, brandId, brandName }) => ({
				text: `${name} - ${brandName}`,
				value: id,
				brandId: brandId
			}))

			if (this.cookieTextTemplate.brandId === UNIVERSAL_BRAND) {
				footers = footers.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				footers = footers.filter(({ brandId }) => brandId === UNIVERSAL_BRAND || brandId === this.cookieTextTemplate.brandId)
			}

			return [
				{
					text: 'None',
					value: null
				},
				...footers.sort((a, b) => a.text.localeCompare(b.text))
			]
		}
	},
	created () {
		this.loadHeadersFooters()
		if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) {
			this.scrollToTop()
		}
	},
	methods: {
		async loadHeadersFooters () {
			const [
				{ data: headers },
				{ data: footers }
			] = await Promise.all([
				getHeaders(headersAndFootersTypeIdEnum.CONSENT_BANNERS),
				getFooters(headersAndFootersTypeIdEnum.CONSENT_BANNERS)
			])
			this.headers = headers ?? []
			this.footers = footers ?? []
		},
		openCreateHeaderFooter (formType) {
			this.showHeaderFooterForm = false
			this.showHeaderFooterForm = true
			this.selectedFormType = formType
			this.headerFooterFormData = null
		},
		async openEditHeaderFooter (formType, headerFooterId) {
			this.selectedFormType = formType
			if (this.selectedFormType === 'Header') {
				const matchingHeader = this.headers.find(({ id }) => id === headerFooterId)
				matchingHeader.brand = matchingHeader.brandId
				this.headerFooterFormData = matchingHeader
			} else {
				const matchingFooter = this.footers.find(({ id }) => id === headerFooterId)
				matchingFooter.brand = matchingFooter.brandId
				this.headerFooterFormData = matchingFooter
			}
			this.showHeaderFooterForm = true
		},
		setSelectedHeaderFooter (type, id) {
			if (type === 'header') {
				this.cookieTextTemplate.headerId = id
			} else if (type === 'footer') {
				this.cookieTextTemplate.footerId = id
			}
		},
		scrollToTop () {
			if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) {
				document.querySelector('#manage_cookie_banner_container').scrollTo(0, 0)
			}
		},
		backToOverview () {
			this.scrollToTop()
			if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) {
				this.$emit('close')
			} else {
				this.$router.push({ name: COOKIE_TEXT_TEMPLATES })
			}
		},
		async submit () {
			const cookieTextTemplate = this.cookieTextTemplate
			delete cookieTextTemplate.createdDate
			if (this.cloneMode) delete cookieTextTemplate.id
			if (this.isEdit) {
				await putCookieTextTemplate(cookieTextTemplate)
				this.$emit('submit')
				this.showSnackbar('Cookie text template updated')
			} else {
				await postCookieTextTemplate(cookieTextTemplate).then(response => {
					this.$emit('submit')
					this.$emit('get-new-text-template-id', response.data.newCookieTextTemplateId)
				})
				this.showSnackbar('Cookie text template created')
			}
			this.backToOverview()
		}
	}
}
</script>
